import { useQuery } from '@urql/vue';
import type { MaybeRef, Ref } from 'vue';
import type { RawAddressbaseListItemFragment } from '../gql/fragments/__generated/RawAddressbaseListItem';
import {
  AddressbaseSearchDocument,
  type AddressbaseSearchQuery,
  type AddressbaseSearchQueryVariables,
} from '../gql/queries/__generated/AddressbaseSearch';
import {
  type AddressbaseFilter,
  type AddressbaseOrder,
  AddressbaseSortFields,
} from '../gql/schema';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { ImxPlatformObject } from '../models/ImxPlatformObject';
import type { Nullable } from '../models/CustomUtilityTypes';

export default async (
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  userFilter: MaybeRef<Nullable<AddressbaseFilter>> = ref<AddressbaseFilter>(
    {}
  ),
  baseFilter: MaybeRef<Nullable<AddressbaseFilter>> = ref<AddressbaseFilter>(
    {}
  ),
  orderBy: MaybeRef<AddressbaseOrder[]> = [
    { field: AddressbaseSortFields.PseudoRandom },
  ],
  pauseQuery: MaybeRef<boolean> = false,
  pageSize: Ref<number> = ref<number>(10)
) => {
  const { locale } = useI18n();
  const instanceConfig = useWhlInstanceConfig();

  const filter = computed(() => ({
    and: [toValue(baseFilter), toValue(userFilter)],
  }));

  const { fetching, data, error, pause, resume } = await useQuery<
    AddressbaseSearchQuery,
    AddressbaseSearchQueryVariables
  >({
    query: AddressbaseSearchDocument,
    variables: {
      language: locale,
      appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      filter: filter,
      orderBy,
      listImageFilter:
        instanceConfig.value.poi?.list?.filter ??
        instanceConfig.value.defaults?.list?.filter,
      listImageFallback:
        instanceConfig.value.poi?.list?.fallback ??
        instanceConfig.value.defaults?.list?.fallback,
      pageSize: pageSize,
    },
    pause: pauseQuery,
  });

  const totalRecords: Ref<number> = computed(() => {
    return data.value?.pois?.pagination?.totalRecords ?? 0;
  });

  const pois: Ref<RawAddressbaseListItemFragment[]> = computed(() => {
    return (data.value?.pois?.nodes ?? []).map((item) => ({
      ...item,
      title: toValue(useImxPlatformObjectHtmlTitle(item as ImxPlatformObject)),
      description: toValue(
        useImxPlatformObjectHtmlDescription(item as ImxPlatformObject)
      ),
      //mapImxPlatformImage(event.keyvisual),
    }));
  });

  return { fetching, error, data, totalRecords, pois, pause, resume };
};
